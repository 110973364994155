var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "pa-md-4 mb-12", attrs: { justify: "center" } },
        [_c("h1", [_vm._v("Nous sécurisons votre connexion")])]
      ),
      _c(
        "v-row",
        { staticClass: "pa-md-4 mb-12", attrs: { justify: "center" } },
        [
          _vm.loading
            ? _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pa-md-4 mb-12", attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-md-4 mb-12", attrs: { justify: "center" } },
            [
              !_vm.loading
                ? _c(
                    "span",
                    {
                      staticClass: "text-h5 d-flex justify-center",
                      staticStyle: { color: "red" },
                    },
                    [_vm._v(" " + _vm._s(_vm.errorText_1) + " ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c("span", { staticClass: "text-h5 d-flex justify-center" }, [
                    _vm._v(" " + _vm._s(_vm.errorText_2) + " "),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }